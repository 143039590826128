import React, { StrictMode } from 'react'
import * as ReactDOM from 'react-dom/client'
import App from './components/App'
import reportWebVitals from './reportWebVitals'
import * as serviceWorker from './serviceWorker'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom'
import { Provider } from 'react-redux'
import configureStore from './store'

const container = document.getElementById('root')
const root = ReactDOM.createRoot(container)

const store = configureStore()

root.render(
  <StrictMode>
    <Provider store={store}>
      <Router>
        <Routes>
          <Route exact path='/' element={<App />} />
          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
      </Router>
    </Provider>
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
