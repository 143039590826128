import React from 'react'
import {
  ChakraProvider,
  Container
} from '@chakra-ui/react'
import Navbar from './Navbar'
import Main from './Main'
import { theme } from '../utils'

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Container maxW='container.sm'>
        <Navbar />
        <Main />
      </Container>
    </ChakraProvider>
  )
}

export default App
