import {
    NumberInput,
    NumberInputField,
    NumberIncrementStepper,
    NumberInputStepper,
    NumberDecrementStepper,
    Text,
    Box
} from '@chakra-ui/react'

const InputNumber = ({label, value, min, step, onChange}) => {

    return(
        <Box>
            <Text>{label}</Text>
            <NumberInput value={value} min={min} step={step} onChange={onChange}>
                <NumberInputField />
                <NumberInputStepper>
                    <NumberIncrementStepper/>
                    <NumberDecrementStepper/>
                </NumberInputStepper>
            </NumberInput>
        </Box>  
    )
}

export default InputNumber