import { extendTheme } from '@chakra-ui/react'

export const toMinSec = (seconds) => {
    let min =  Math.floor(seconds / 60).toLocaleString('es-ES', {minimumIntegerDigits: 2})
    let sec = (seconds % 60).toLocaleString('es-ES', {minimumIntegerDigits: 2})
    return min+':'+sec
}

export const toHoursString = (seconds) => {
    let hours = Math.floor(seconds / (60 * 60)) 
    let min = (seconds - (hours * 60 * 60)) / 60
    return `${hours} h ${min} min`
}

export const theme = extendTheme({
    config: {
        initialColorMode: 'dark'
    }
})