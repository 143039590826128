const getPomodoroState = state => state.pomodoro

const stages = ['Pomodoro', 'Short Break', 'Long Break']

export const getTime = state => getPomodoroState(state).time

export const getStage = state => getPomodoroState(state).stage

export const getStageString = state => stages[getPomodoroState(state).stage]

export const getConfig = state => getPomodoroState(state).config