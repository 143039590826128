import { Box, Divider, Flex, Heading } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getTime, getStageString } from '../selectors'
import { toMinSec } from '../utils'
import SettingsMenu from './SettingsMenu'

const Navbar = () => {

    const time = useSelector(getTime)
    const stageString = useSelector(getStageString)

    useEffect(() => {
        document.title = `${toMinSec(time)} - ${stageString}`
    }, [time,stageString])

    return (
        <>
            <Flex
                as='nav'
                w='100%'
                direction='row'
                justifyContent='space-between'
                mt={1}
            >
                <Box p={1}>
                    <Heading display='inline'>PomoTimer</Heading>
                </Box>
                <Box p={1}>
                    <SettingsMenu />
                </Box>
            </Flex>
            <Divider />
        </>
    )
}

export default Navbar