import { 
    AlertDialog, 
    AlertDialogOverlay, 
    AlertDialogContent, 
    AlertDialogHeader,  
    AlertDialogBody, 
    AlertDialogFooter,
    Button
} from '@chakra-ui/react'

const Dialog = ({header, body, isOpen, onClose, onAction, closeText, actionText}) => {

    const handleAction = () => {
        onAction()
        onClose()
    }

    return(
        <AlertDialog isOpen={isOpen} onClose={onClose}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontWeight='bold'>
                        {header}
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        {body}
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button onClick={onClose}>
                            {closeText}
                        </Button>
                        <Button onClick={handleAction} ml='3'>
                            {actionText}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )

}

export default Dialog