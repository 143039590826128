import { combineReducers } from 'redux'
import * as actionTypes from '../actionTypes'

const initialState = {
    time: 1500,
    stage: 0,
    config: [1500, 300, 900]
}

const time = (state = initialState.time, action) => {
    switch (action.type) {
        case actionTypes.TIME_ELAPSE:
            if (state === 0) return 0
            return state - 1
        case actionTypes.TIME_RESET:
            return action.time*60
        default:
            return state
    }
}

const stage = (state = initialState.stage, action) => {
    switch (action.type) {
        case actionTypes.CHANGE_STAGE:
            return action.stage
        default:
            return state
    }
}

const config = (state = initialState.config, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_CONFIG:
            return action.config
        default:
            return state
    }
}

const PomodoroReducer = combineReducers({time, stage, config})

export default PomodoroReducer