import { useEffect, useState } from 'react'
import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    useDisclosure,
    IconButton
} from '@chakra-ui/react'
import { useRef } from 'react'
import { IoMdSettings as SettingsIcon } from 'react-icons/io'
import InputNumber from './InputNumber'
import { useDispatch } from 'react-redux'
import { updateConfig } from '../actions'

const SettingsMenu = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = useRef()
    const [config, setConfig] = useState([25, 5, 15])
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(updateConfig(config))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config])

    const handleChange = (stage, value) => {
        let newConfig = [...config]
        newConfig[stage] = value
        setConfig(newConfig) 
    }

    return (
        <>
            <IconButton icon={<SettingsIcon/>} ref={btnRef} onClick={onOpen} />
            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={onClose}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerHeader>
                        Time Settings
                    </DrawerHeader> 
                    <DrawerBody>
                        <InputNumber
                            label='Pomodoro (min):'
                            value={config[0]}
                            min={1}
                            step={1}
                            onChange={(v) => handleChange(0,v)}
                        />
                        <InputNumber
                            label='Short Break (min):'
                            value={config[1]}
                            min={1}
                            step={1}
                            onChange={(v) => handleChange(1,v)}
                        />
                        <InputNumber
                            label='Long Break (min):'
                            value={config[2]}
                            min={1}
                            step={1}
                            onChange={(v) => handleChange(2,v)}
                        />
                    </DrawerBody>
                </DrawerContent> 
            </Drawer>
        </>
    )
}

export default SettingsMenu