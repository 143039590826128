import { Box, Button, Container, Flex, Heading, useDisclosure} from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getTime, getStage, getConfig } from '../selectors'
import { timeElapse, changeStage } from '../actions'
import { toMinSec, toHoursString } from '../utils'
import Dialog from './Dialog'

const stages = ['Pomodoro', 'Short Break', 'Long Break']

const Main = () => {

    const dispatch = useDispatch()
    const currentStage = useSelector(getStage)
    const time = useSelector(getTime)
    const [isRunnig, setIsRunnig] = useState(false)
    const [timer, setTimer] = useState(null)
    const [clickedStage, setClickedStage] = useState(0)
    const {isOpen, onOpen, onClose} = useDisclosure()
    const [pomodoros, setPomodoros] = useState(0)
    const buttonAudio = useMemo(() => new Audio('./audio/button-press.wav'), [])
    const finishAudio = useMemo(() => new Audio('./audio/alarm-digital.mp3'), [])
    const config = useSelector(getConfig)

    useEffect(() => {
        if (time === 0) {
            clearInterval(timer)
            setIsRunnig(false)
            finishAudio.play()
        }
    }, [time, timer, finishAudio, setIsRunnig])

    useEffect(() => {
        if (pomodoros !== 0) {
            let next = (pomodoros % 4 === 0) ? 2 : 1
            dispatch(changeStage(next))
        }
    }, [pomodoros, dispatch])

    const stopTimer = () => {
        clearInterval(timer)
        setIsRunnig(false)
    }

    const startTimer = () => {
        dispatch(timeElapse())
        setTimer(setInterval(() => dispatch(timeElapse()), 1000))
        setIsRunnig(true)
    }

    const resumeTimer = () => {
        buttonAudio.play()
        if (isRunnig) {
            stopTimer()
        } else {
            startTimer()
        }
    }

    const handleChangeStage = (i) => {
        setClickedStage(i)
        if (!isRunnig) {
            stopTimer()
            dispatch(changeStage(i, config[i]))
        } else {
            onOpen()
        }
    }

    const nextStage = () => {
        if (currentStage === 0) {
            setPomodoros(value => value +1)
        } else {
            dispatch(changeStage(0, config[0]))
        }
    }

    return(
        <Container mt={10}>
            <Box p={5} width='100%' borderRadius='5px' backgroundColor='gray.700'>
                <Flex direction='row' justifyContent='center' gap={5}>
                    {stages.map((st,i) => <Button fontSize={{base:'0.8rem', sm: '1rem'}} backgroundColor={currentStage===i ? 'gray.800' : ''} key={st} onClick={() => handleChangeStage(i)}>{st}</Button>)}
                </Flex>
                <Box textAlign='center' fontSize={{base:'90px', sm: '120px'}}>
                    <b>{toMinSec(time)}</b>
                </Box>
                <Flex direction='row' justifyContent='center'>
                    {time !== 0 
                    ? <Button onClick={resumeTimer}>{isRunnig ? 'STOP' : 'START'}</Button>
                    : <Button onClick={nextStage}>Next</Button>
                    }
                </Flex>
            </Box>
            <Flex p={5} direction='column' alignItems='center'>
                <Heading>
                    #{pomodoros} pomodoros
                </Heading>
                <Heading>
                    {toHoursString(pomodoros * 1500)}
                </Heading>
            </Flex>
            <Dialog
                isOpen={isOpen}
                onClose={onClose}
                header='Change stage'
                body='Timer is runnig, are you sure?'
                onAction={() => {stopTimer();dispatch(changeStage(clickedStage,config[clickedStage]))}}
                closeText='Cancel'
                actionText='Change'
            />
        </Container>
    )
}

export default Main